import gql from 'graphql-tag'

export const GET_REALM_USER_QUERY = gql`
  query RealmUsersQuery($usersWhere: User_bool_exp) {
    User(where: $usersWhere, order_by: { familyName: asc_nulls_last }) {
      familyName
      givenName
      email
      createdAt
      id
      name
      cachedRoles
      isDisplayBlocked
      Realm_Users {
        Realm {
          id
        }
      }
      Site_Users_aggregate(
        where: {
          Site: { Study_Sites: { Study: { isBlocked: { _eq: false } } } }
        }
      ) {
        aggregate {
          count
        }
      }
      Site_Users {
        Site {
          name
          id
          isBlocked
          Study_Sites(where: { Study: { isBlocked: { _eq: false } } }) {
            Study {
              id
              name
              isBlocked
            }
          }
        }
      }
    }
  }
`
