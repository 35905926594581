import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { GET_USERS_ERROR_MESSAGE } from '../components/UserDetails/userUtils'
import { GET_REALM_USER_QUERY } from '../queries/getRealmUsers'
import { ALL_REALMS_ID } from '../utils/constants'
import { useGlobalStore } from './useGlobalStore'
import { useRoleAccess } from './useRoleAccess'
import { navigate } from 'gatsby'

export function useHandleUsersList({ usersSectionRef, routeRealm }) {
  const { realmId } = useGlobalStore()
  const [gridApi, setGridApi] = useState()
  const [usersSet, setUsersSet] = useState([])
  const [filterValue, setFilterValue] = useState('')
  const [usersSetRaw, setUsersSetRaw] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [showUserDetail, setShowUserDetail] = useState(false)
  const { canOpenUserDetails } = useRoleAccess()

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  const { loading, data: users } = useQuery(GET_REALM_USER_QUERY, {
    skip: !realRealmId && realRealmId !== ALL_REALMS_ID,
    variables: {
      usersWhere: realRealmId > 0 ? { Realm_Users: { realm_id: { _eq: realRealmId } } } : {}
    },
    onError: _ => toaster.danger(GET_USERS_ERROR_MESSAGE)
  })

  function handleUserDetailsClose() {
    setShowUserDetail(false)
  }

  useEffect(() => {
    function onUsersQuerySuccess(result) {
      if (result?.User) {
        const users = result.User
        setUsersSet(users)
        setUsersSetRaw(users)
      }
    }
    onUsersQuerySuccess(users)
  }, [users])

  useEffect(() => {
    if (gridApi) {
      if (loading) {
        gridApi.showLoadingOverlay()
      } else if (!loading && users?.length === 0) {
        gridApi.showNoRowsOverlay()
      } else if (!loading && users?.length > 0) {
        gridApi.hideOverlay()
      }
    }
  }, [users, loading, gridApi])

  function handleUserSelect({ data }) {
    if (canOpenUserDetails) {
      setSelectedUser(data)
      setShowUserDetail(true)
      navigate(`/realm/${realRealmId}/user/${data.id}`)
      //usersSectionRef.current.classList.add('hide')
    }
  }

  function handleUsersFilter(query) {
    setFilterValue(query)

    if (query === '') {
      return setUsersSet(usersSetRaw)
    }

    setUsersSet(
      usersSetRaw.filter(user => {
        const { givenName, familyName, email } = user
        const name = `${givenName} ${familyName} ${email}`
        return name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      })
    )
  }

  function onGridReady(params) {
    setGridApi(params?.api)
  }

  return {
    usersSet,
    selectedUser,
    showUserDetail,
    filterValue,
    onGridReady,
    handleUserDetailsClose,
    handleUserSelect,
    handleUsersFilter
  }
}
